'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import { Context } from './MenuContext';
import { useCookies } from 'next-client-cookies';
import { usePathname } from 'next/navigation';
import { usePreviousImmediate, useThrottle, useWindowScrollPosition } from 'rooks';
interface ProviderProps {
  children: ReactNode;
}
function Provider({
  children
}: ProviderProps) {
  const cookies = useCookies();
  const pathname = usePathname();
  const position = useWindowScrollPosition();
  const prevPathname = usePreviousImmediate(pathname);
  const [isCollapsed, setIsCollapsed] = useState(cookies.get('is-menu-collapsed') === 'true' || false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setHideNavigation] = useState(false);
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(position.scrollY);
  const [throttledSetHideNavigation] = useThrottle(setHideNavigation, 50);
  const [throttledSetPrevScrollPosition] = useThrottle(setPrevScrollPosition, 100);
  useEffect(() => {
    cookies.set('is-menu-collapsed', isCollapsed.toString());
  }, [isCollapsed]);
  useEffect(() => {
    if (position.scrollY >= 0) {
      if (position.scrollY > prevScrollPosition + 50 && position.scrollY > 100) {
        throttledSetHideNavigation(true);
      } else if (position.scrollY < 50 || position.scrollY < prevScrollPosition - 50) {
        throttledSetHideNavigation(false);
      }
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      throttledSetPrevScrollPosition(position.scrollY);
      setIsScrolledBottom(Math.ceil(position.scrollY + clientHeight) >= scrollHeight);
    }
  }, [position.scrollY]);
  useEffect(() => {
    if (prevPathname !== pathname) {
      setIsOpen(false);
      throttledSetHideNavigation(false);
    }
  }, [prevPathname, pathname]);
  return <>
    <Context.Provider value={{
      collapsed: isCollapsed,
      open: isOpen,
      hidden: isHidden,
      scrolledBottom: isScrolledBottom,
      setIsCollapsed,
      setIsOpen
    }} data-sentry-element="unknown" data-sentry-source-file="MenuProvider.tsx">{children}</Context.Provider>
  </>;
}
export default Provider;