'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import WebSocketInstance from '@utils/ws';
import { Context } from './WebSocketContext';
import { useDidUpdate, useIntervalWhen, usePreviousImmediate } from 'rooks';
import { userSession } from '@/app/actions';
interface ProviderProps {
  children: ReactNode;
}
function Provider({
  children
}: ProviderProps) {
  const [session, setSession] = useState<any>();
  const [wsp, setWebSocketInstance] = useState<any>();
  const prevAccessToken = usePreviousImmediate(session?.accessToken);
  useEffect(() => {
    (async (): Promise<void> => {
      const session = await userSession();
      setSession(JSON.parse(session));
    })();
  }, []);
  useDidUpdate(() => {
    (async (): Promise<void> => {
      try {
        const accessToken = session?.accessToken;
        if (prevAccessToken !== accessToken) {
          if (wsp) {
            wsp.close();
          }
          const newWsp = new WebSocketInstance(session?.accessToken as string, '');
          setWebSocketInstance(newWsp.getInstance());
        }
      } catch (error) {
        console.error('WS Connection failed to open', error);
      }
    })();
  }, [prevAccessToken, session?.accessToken]);
  useDidUpdate(() => {
    (async (): Promise<void> => {
      if (!wsp?.isOpened) {
        try {
          await wsp?.open();
        } catch (error) {
          console.error('Unable to connect to Websocket', error);
        }
      }
    })();
    return () => wsp?.close();
  }, [wsp]);
  useIntervalWhen(() => {
    (async (): Promise<void> => {
      if (session && Date.now() > session.expiresAt * 1000 - 60000) {
        const newSession = await userSession();
        setSession(JSON.parse(newSession));
      }
    })();
  }, 30000, session?.accessToken);
  return <>
    <Context.Provider value={{
      ws: wsp
    }} data-sentry-element="unknown" data-sentry-source-file="WebSocketProvider.tsx">{children}</Context.Provider>
  </>;
}
export default Provider;