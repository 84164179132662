'use client';

import React, { ReactElement, ReactNode } from 'react';
import { Context } from './UserContext';
import type { UserType } from '@/types/users';
type Props = {
  children: ReactNode;
  user: UserType;
};
const Provider = ({
  children,
  user
}: Props): ReactElement => {
  return <Context.Provider value={user} data-sentry-element="unknown" data-sentry-component="Provider" data-sentry-source-file="UserProvider.tsx">
      {children}
    </Context.Provider>;
};
export default Provider;