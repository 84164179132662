'use client';

import React, { ReactElement } from 'react';
import { useCurrentUser } from '@hooks';
const DefaultFallbackComponent = (): ReactElement => <></>;
type Props = object;
const withUser = <T extends Props,>(WrappedComponent: React.ComponentType<T>, FallbackComponent = DefaultFallbackComponent): React.ComponentType<T> => {
  const ComponentWithSession = (props: T): ReactElement => {
    const user = useCurrentUser();
    if (user) {
      return <WrappedComponent user={user} {...props as T} />;
    }
    return <FallbackComponent data-sentry-element="FallbackComponent" data-sentry-component="ComponentWithSession" data-sentry-source-file="withUser.tsx" />;
  };
  return ComponentWithSession;
};
export default withUser;