'use client';

import React, { ReactElement } from 'react';
import { css, cx } from 'styled-system/css';
import { useNotificationsContext } from '@hooks';
type Props = {
  classNames?: string;
};
const NotificationBell = ({
  classNames = ''
}: Props): ReactElement => {
  const [unreadTotal] = useNotificationsContext();
  return <span className={cx(styles.main, classNames)} data-sentry-component="NotificationBell" data-sentry-source-file="NotificationBell.tsx">
      {unreadTotal > 0 ? <span className={cx(styles.unread, 'unread')}>
            {unreadTotal}{unreadTotal >= 9 ? '+' : ''}
          </span> : null}
      <i className={cx('icon', styles.icon, 'fa-bell')} />
    </span>;
};
const styles = {
  main: css({
    position: 'relative'
  }),
  icon: cx('fal', css({
    display: 'flex !important',
    justifyContent: 'center',
    w: '6 !important'
  })),
  unread: css({
    bg: 'highlight',
    border: '2px solid token(colors.background)',
    borderRadius: 'xl',
    display: 'block',
    fontSize: '0.75rem',
    px: 1.5,
    py: 0.5,
    position: 'absolute',
    transform: 'translateX(0.5rem) translateY(-0.5rem)'
  })
};
export default NotificationBell;