'use client';

import React, { ReactElement } from 'react';
import { Toaster } from 'react-hot-toast';
import { css } from 'styled-system/css';
import { token } from 'styled-system/tokens';
const CustomToaster = (): ReactElement => {
  return <Toaster toastOptions={{
    className: styles.toast,
    success: {
      className: styles.success,
      iconTheme: {
        primary: token('colors.foregroundAlt'),
        secondary: token('colors.text')
      }
    },
    error: {
      className: styles.error,
      iconTheme: {
        primary: token('colors.error'),
        secondary: token('colors.highlightText')
      }
    }
  }} data-sentry-element="Toaster" data-sentry-component="CustomToaster" data-sentry-source-file="Toaster.tsx" />;
};
const styles = {
  toast: css({
    bg: 'foreground !important',
    borderRadius: 'md !important'
  }),
  success: css({
    bg: 'foreground !important',
    border: '1px solid token(colors.foregroundAlt) !important',
    color: 'text !important',
    textAlign: 'center'
  }),
  error: css({
    bg: 'error !important',
    border: '1px solid token(colors.foregroundAlt) !important',
    color: 'highlightText !important',
    textAlign: 'center'
  })
};
export default CustomToaster;